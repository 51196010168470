import { Grid } from "@mui/material"


const BrandCard = ({image, imageAlt, id}) => {
  return (
    <Grid item xs={6} md={4} lg={3}>
      <div style={{ 
          backgroundColor: "#FE8A01", 
          borderRadius: "25px", 
          overflow: "hidden", 
          aspectRatio: "1 / 1",
          display: "flex",
          alignItems: "center",
          padding: "20px" }}>
        <img 
          src={image}
          alt={imageAlt}
          style={{ width: "100%", objectFit: "cover" }}
        ></img>
      </div>
    </Grid>
  );
}

export default BrandCard
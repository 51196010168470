import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { NumericFormat } from "react-number-format";
import {
  Backdrop,
  Container,
  Fade,
  Grid,
  Modal,
  Button,
  TextField,
  Autocomplete,
  Alert,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import CustomerService from "../services/customer/customerService";
import Navigation from './navigation'
import ImageCarousel from "./components/carousel/carousel";
import ItemCard from "./components/itemCard/itemCard";
import BrandCard from "./components/itemCard/BrandCard";

export default function HomePage() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const cleanedBackendUrl = backendUrl.replace("/backend", "");
  const [ShowSuccess, setShowSuccess] = useState(false);
  const [MsgSuccess, setMsgSuccess] = useState("");
  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState();
  const [newRelease, setNewRelease] = useState([]);
  const [hotSale, setHotSale] = useState([]);
  const [products, setProducts] = useState([]);
  const [bannner, setBanner] = useState([]);
  const navigate = useNavigate()

  useEffect( () => {
    async function fetchItems() {
      try {
        const responseNewRelease = await CustomerService.getListItem('new-release')
        const responseHotSale = await CustomerService.getListItem('hot-sale')
        const responseProducts = await CustomerService.getListItem('products')
        const responseBanner = await CustomerService.getListItem('banner')

        setNewRelease(responseNewRelease.data.itemList.slice(0, 6))
        setHotSale(responseHotSale.data.itemList.slice(0, 6))
        setProducts(responseProducts.data.itemList.slice(0, 6))
        setBanner(responseBanner.data.itemList.slice(0, 6))

      } catch (error) {
        console.error(error)
      }
    }

    fetchItems()
  }, [])

  const btnMoreStyle = {
    borderRadius: "100px",
    width: "20vw",
    height: "50px",
    backgroundColor: "#FE8A01",
    color: "black",
    padding: 5,
    marginLeft: 25,
    textTransform: "none",
    fontSize: "1em"
  };

  const brands = [
    "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a6/Logo_NIKE.svg/2560px-Logo_NIKE.svg.png",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a6/Logo_NIKE.svg/2560px-Logo_NIKE.svg.png",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a6/Logo_NIKE.svg/2560px-Logo_NIKE.svg.png",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a6/Logo_NIKE.svg/2560px-Logo_NIKE.svg.png",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a6/Logo_NIKE.svg/2560px-Logo_NIKE.svg.png",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a6/Logo_NIKE.svg/2560px-Logo_NIKE.svg.png",
  ]

  return (
    <>
      <Navigation />

      <Container component="main">
        {ShowSuccess && (
          <Alert variant="filled" severity="success" style={{ marginTop: 20 }}>
            {MsgSuccess}
          </Alert>
        )}
        {showError && (
          <Alert variant="filled" severity="error" style={{ marginTop: 20 }}>
            {msgError}
          </Alert>
        )}

        <Grid container marginTop={5}>
          <Grid item xs={12}>
            {/* <ImageCarousel images={images} /> */}
            <ImageCarousel images={bannner} />
          </Grid>
        </Grid>

        <Grid container marginTop={5}>
          <Grid item xs={12}>
            <Typography variant="h4" fontWeight="bold" color={"#FE8A01"}>NEW RELEASE</Typography>
          </Grid>
          {
            newRelease.map((item) => (
              <ItemCard
                key={item.id}
                item={item} />
            ))
          }
          <Grid item xs={12} marginTop={3} style={{ display: "flex", justifyContent: "center" }}>
            <Button style={btnMoreStyle} onClick={() => navigate('/items/new-release')}>TAP FOR MORE</Button>
          </Grid>
        </Grid>

        <Grid container marginTop={5}>
          <Grid item xs={12}>
            <Box style={{ width: "100%", height: "400px", backgroundColor: "#FE8A01"  }}></Box>
          </Grid>
        </Grid>
        
        <Grid container marginTop={5}>
          <Grid item xs={12}>
            <Typography variant="h4" fontWeight="bold" color={"#FE8A01"}>HOT SALE</Typography>
          </Grid>
          {
            hotSale.map((item) => (
              <ItemCard
                key={item.id}
                item={item} />
            ))
          }
          <Grid item xs={12} marginTop={3} style={{ display: "flex", justifyContent: "center" }}>
            <Button style={btnMoreStyle} onClick={() => navigate('/items/hot-sale')}>TAP FOR MORE</Button>
          </Grid>
        </Grid>

        <Grid container marginTop={5}>
          <Grid item xs={12}>
            <Box style={{ width: "100%", height: "400px", backgroundColor: "#FE8A01"  }}></Box>
          </Grid>
        </Grid>

        <Grid container marginTop={5} spacing={5}>
          <Grid item xs={12}>
            <Typography variant="h4" fontWeight="bold" color={"#FE8A01"}>BRAND</Typography>
          </Grid>
          {
            brands.map((image, index) => (
              <BrandCard
                key={index}
                id={index}
                image={image}
                imageAlt="nike" />
            ))
          }
          <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
            <Button style={btnMoreStyle}>TAP FOR MORE</Button>
          </Grid>
        </Grid>

        <Grid container marginTop={5} marginBottom={5}>
          <Grid item xs={12}>
            <Typography variant="h4" fontWeight="bold" color={"#FE8A01"}>PRODUCT</Typography>
          </Grid>
          {
            products.map((item) => (
              <ItemCard
                key={item.id}
                item={item} />
            ))
          }
          <Grid item xs={12} marginTop={3} style={{ display: "flex", justifyContent: "center" }}>
            <Button style={btnMoreStyle} onClick={() => navigate('/items/products')}>TAP FOR MORE</Button>
          </Grid>
        </Grid>

      </Container>
    </>
  )
}
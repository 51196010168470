import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import Logo from "../assets/logo.png";
// import Background from "../assets/EyeClose.png";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    padding: 20,
  },
  logo: {
    width: 100,
    marginBottom: 20,
  },
  header: {
    fontSize: 12,
    textAlign: "left",
    marginBottom: 10,
  },
  section: {
    marginBottom: 10,
  },
  title: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 20,
  },
  infoTable: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  infoCell: {
    width: "48%",
    padding: 10,
    border: "1px solid #000",
    fontSize: 12,
  },
  salaryTable: {
    fontSize: 12,
    width: "100%",
    marginBottom: 20,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 5,
  },
  totalSalary: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 20,
  },
});

const convertToHoursAndMinutes = (decimalTime) => {
  const hours = Math.floor(decimalTime);
  const minutes = Math.round((decimalTime - hours) * 60);

  return `${hours} Jam ${minutes} Menit`;
}

const SalarySlipPDF = ({ employeeData, salaryData }) => {
  // Calculate salary breakdown
  const baseSalary = salaryData?.monthlyPayGross || 0;
  const overtime = salaryData?.overtimePay || 0;
  const foodAllowance = salaryData?.foodAllowance || 0;
  
  const lateDeduction = salaryData?.lateDeduction || 0;
  const absentDeduction = salaryData?.absentDeduction || 0;
  const netDeduction = salaryData?.netDeduction || 0;

  const totalHoursWorked = salaryData?.totalHoursWorked || 0;

  let totalSalary = baseSalary + overtime + foodAllowance - lateDeduction - absentDeduction;

    if (salaryData?.debitTransaction) {
      salaryData.debitTransaction.forEach(debit => {
        totalSalary += debit.amount;
      });
    }
  
    if (salaryData?.creditTransaction) {
      salaryData.creditTransaction.forEach(credit => {
        totalSalary -= credit.amount;
      });
    }

  if (totalSalary < 0) totalSalary = 0;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Background image */}
        {/* <Image
          src={Background}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            opacity: 0.1,
          }}
        /> */}

        {/* Logo */}
        <View xs={3}>
          <Image src={Logo} style={styles.logo} />
        </View>

        <Text style={{ textAlign: "center", paddingBottom: 15 }}>
          SLIP GAJI KARYAWAN
        </Text>

        {/* Header */}
        <Text style={styles.header}>LIQUE STORE</Text>
        <Text style={styles.header}>
          Jl. Raya Pagedangan No.18, Kadu Sirung, Kec. Pagedangan, Tangerang,
          Banten 15336
        </Text>

        {/* Employee and Salary Info */}
        <View style={styles.infoTable}>
          <View style={styles.infoCell}>
            <Text>Nama: {employeeData?.fullname || "N/A"}</Text>
            <Text>Jabatan: {employeeData?.position || "N/A"}</Text>
            <Text>No HP: {employeeData?.phoneNumber || "N/A"}</Text>
          </View>
          <View style={styles.infoCell}>
            <Text>Email: {employeeData?.email || "N/A"}</Text>
            <Text>Bulan: {salaryData?.month || "N/A"}</Text>
            <Text>Tahun: {salaryData?.year || "N/A"}</Text>
          </View>
        </View>

        {/* Salary Breakdown */}
        <View style={styles.salaryTable}>
          <View style={styles.row}>
            <Text>Total Jam Bekerja</Text>
            <Text>{convertToHoursAndMinutes(totalHoursWorked)}</Text>
          </View>
          <View style={styles.row}>
            <Text>Gaji Pokok</Text>
            <Text>{`Rp ${baseSalary.toLocaleString("id-ID")}`}</Text>
          </View>

          <View style={styles.row}></View>

          <View style={styles.row}>
            <Text>(+)</Text>
          </View>
          <View style={styles.row}>
            <Text>Bonus Lembur</Text>
            <Text>{`Rp ${overtime.toLocaleString("id-ID")}`}</Text>
          </View>
          <View style={styles.row}>
            <Text>Uang Makan</Text>
            <Text>{`Rp ${foodAllowance.toLocaleString("id-ID")}`}</Text>
          </View>
          <View style={styles.row}>
            <Text>Absensi</Text>
            <Text>{`Rp ${(0).toLocaleString("id-ID")}`}</Text>
          </View>
          <View style={styles.row}>
            <Text>Ketepatan Waktu</Text>
            <Text>{`Rp ${(0).toLocaleString("id-ID")}`}</Text>
          </View>
          <View style={styles.row}>
            <Text>Bonus(opsional)</Text>
            <Text>{`Rp ${(0).toLocaleString("id-ID")}`}</Text>
          </View>
          <View style={styles.row}>
            <Text>Kenaikan Gaji(opsional)</Text>
            <Text>{`Rp ${(0).toLocaleString("id-ID")}`}</Text>
          </View>
          {salaryData?.debitTransaction &&
            salaryData.debitTransaction.map((debit, index) => (
              <View style={styles.row} key={index}>
                <Text>{debit.name}</Text>
                <Text>{`Rp ${debit.amount.toLocaleString("id-ID")}`}</Text>
              </View>
            ))}

          <View style={styles.row}></View>

          <View style={styles.row}>
            <Text>(-)</Text>
          </View>
          <View style={styles.row}>
            <Text>Potongan Keterlambatan</Text>
            <Text>{`Rp ${lateDeduction.toLocaleString("id-ID")}`}</Text>
          </View>
          <View style={styles.row}>
            <Text>Potongan Absensi</Text>
            <Text>{`Rp ${absentDeduction.toLocaleString("id-ID")}`}</Text>
          </View>
          <View style={styles.row}>
            <Text>Total Potongan</Text>
            <Text>{`Rp ${netDeduction.toLocaleString("id-ID")}`}</Text>
          </View>
          {salaryData?.creditTransaction &&
            salaryData.creditTransaction.map((credit, index) => (
              <View style={styles.row} key={index}>
                <Text>{credit.name}</Text>
                <Text>{`Rp ${credit.amount.toLocaleString("id-ID")}`}</Text>
              </View>
            ))}

        </View>

        {/* Total Salary */}
        <Text style={styles.totalSalary}>
          TOTAL GAJI: {`Rp ${totalSalary.toLocaleString("id-ID")}`}
        </Text>
      </Page>
    </Document>
  );
};

export default SalarySlipPDF;

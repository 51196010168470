import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useNavigate, useNavigationType, useParams } from "react-router-dom";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Container,
  Fade,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Modal,
  Snackbar,
  SnackbarContent,
  Typography,
} from "@mui/material";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Navigation from "./navigation";
import ImageCarousel from "./components/carousel/carousel";
import ItemCard from "./components/itemCard/itemCard";
import CustomerService from "../services/customer/customerService";
import CartService from "../services/customer/cartService";
import { useAuth } from "../authContext";

const ItemDetailPage = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const cleanedBackendUrl = backendUrl.replace("/backend", "");
  const [ShowSuccess, setShowSuccess] = useState(false);
  const [MsgSuccess, setMsgSuccess] = useState("");
  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState();
  const [isBookmarked, setBookmarked] = useState(false);
  const [similarProduct, setSimilarProduct] = useState([]);
  const [item, setItems] = useState([]);
  const [size, setSize] = useState("");
  const [openAddItem, setOpenAddItem] = useState(false);
  const [addItemState, setAddItemState] = useState(null);
  const [loadingAddItem, setLoadingAddItem] = useState(false);
  const [addItemMessage, setAddItemMessage] = useState("");
  const [addItemButton, setAddItemButton] = useState("");
  const [OpenChooseSize, setopenChooseSize] = useState(false);
  const [OpenSuccessSnack, setopenSuccessSnack] = useState(false);
  const handleCloseChooseSize = () => setopenChooseSize(false);
  const handleOpenChooseSize = () => setopenChooseSize(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const navigationType = useNavigationType();

  const { auth } = useAuth();

  const btnRedStyle = {
    borderRadius: "100px",
    width: "250px",
    maxWidth: "100%",
    height: "50px",
    backgroundColor: "#E50B0B",
    color: "white",
    padding: 5,
    textTransform: "none",
    fontSize: "1em",
  };

  const btnPrimaryStyle = {
    borderRadius: "100px",
    width: "250px",
    maxWidth: "100%",
    height: "50px",
    backgroundColor: "#FE8A01",
    color: "white",
    padding: 5,
    textTransform: "none",
    fontSize: "1em",
  };

  const btnPrimaryOutlineStyle = {
    borderRadius: "100px",
    width: "250px",
    maxWidth: "100%",
    height: "50px",
    backgroundColor: "transparent",
    border: "1px solid #FE8A01",
    color: "#FE8A01",
    padding: 5,
    textTransform: "none",
    fontSize: "1em",
  };

  const btnPrimaryTextStyle = {
    borderRadius: "100px",
    width: "250px",
    maxWidth: "100%",
    height: "50px",
    backgroundColor: "transparent",
    color: "#FE8A01",
    padding: 5,
    textTransform: "none",
    fontSize: "1em",
  };

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    maxHeight: "80vh",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
    textAlign: "center",
    overflowY: "auto",
    overflowX: "hidden",
  };

  const handleBookmark = () => {
    setBookmarked(!isBookmarked);
  };

  const handleChooseSize = (size) => {
    setSize(size);
    handleCloseChooseSize();
  };

  const handleCloseAddItem = () => {
    setAddItemState(null);
    setOpenAddItem(false);
  };

  const handleOpenAddItem = (state) => {
    if (auth?.user) {
      setAddItemState(state);
      setOpenAddItem(true);
    } else {
      navigate("/login");
    }
  };

  const handleBack = () => {
    if (navigationType === "POP") {
      navigate("/customer");
    } else {
      navigate(-1);
    }
  };

  const fetchSimilarItem = async () => {
    try {
      const responseSimilar = await CustomerService.getListItem("");
      setSimilarProduct(responseSimilar.data.itemList.slice(0, 6));
    } catch (error) {
      console.error(error);
    }
  };

  const fetchItemDetail = async () => {
    try {
      const responseItem = await CustomerService.getItemDetail(id);
      setItems(responseItem.data);
    } catch (error) {
      console.error(error);
    }
  };

  const addItem = async () => {
    setLoadingAddItem(true);
    try {
      const response = await CartService.addToCart(auth?.user.id, id);
      if (response) {
        if (addItemState === "buy") {
          navigate("/customer/cart");
        } else if (addItemState === "cart") {
          setopenSuccessSnack(true);
        }
      }
    } catch (error) {
      console.error(error);
      setMsgError("Tambah item gagal");
    } finally {
      setLoadingAddItem(false);
      handleCloseAddItem();
    }
  };

  useEffect(() => {
    fetchSimilarItem();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchItemDetail();
  }, [id]);

  useEffect(() => {
    if (addItemState === "cart") {
      setAddItemButton("Add to Cart");
      setAddItemMessage("Apakah anda ingin menambah produk ini ke cart?");
    } else if (addItemState === "buy") {
      setAddItemButton("Buy Product");
      setAddItemMessage("Apakah anda ingin membeli produk ini?");
    } else {
      setAddItemButton("Ya");
      setAddItemMessage("");
    }
  }, [addItemState]);

  const action = (
    <>
      <Button
        color="secondary"
        size="small"
        onClick={() => navigate("/customer/cart")}
      >
        Open Cart
      </Button>
    </>
  );

  return (
    <>
      <Navigation />

      <Container component="main">
        {ShowSuccess && (
          <Alert variant="filled" severity="success" style={{ marginTop: 20 }}>
            {MsgSuccess}
          </Alert>
        )}
        {showError && (
          <Alert variant="filled" severity="error" style={{ marginTop: 20 }}>
            {msgError}
          </Alert>
        )}

        <Grid container marginTop={5}>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button style={btnRedStyle} onClick={handleBack}>
              TOP PRODUCT
            </Button>
          </Grid>
        </Grid>

        <Grid
          container
          marginTop={5}
          justifyContent={"space-between"}
          style={{
            backgroundColor: "#000",
            color: "#fff",
            padding: "40px",
            borderRadius: "15px",
          }}
        >
          <Grid item xs={12}>
            <ImageCarousel images={item.images || []} />
          </Grid>
          <Grid item xs={12} lg={8} marginTop={5}>
            <Typography variant="h4">{item.name}</Typography>
            <Typography variant="h5">
              <NumericFormat
                value={item.defaultPrice}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                prefix={"Rp. "}
              />
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} marginTop={5}>
            {/* <Button style={{ backgroundColor: "#fff", color: "#000", padding: "10px", borderRadius: "15px", cursor: "pointer", width: "100%" }} onClick={handleOpenChooseSize}>
              <Grid container style={{ backgroundColor: "#fff", color: "#000", padding: "10px", borderRadius: "15px", cursor: "pointer", width: "100%" }}>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Typography variant="h6">SIZE <ArrowDropDownIcon /></Typography>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Typography variant="p">{size === "" ? "-" : size}</Typography>
                </Grid>
              </Grid>
            </Button> */}
            <Grid
              container
              style={{
                backgroundColor: "#fff",
                color: "#000",
                padding: "10px",
                borderRadius: "15px",
                cursor: "pointer",
                width: "100%",
              }}
            >
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography variant="h6">SIZE:</Typography>
              </Grid>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography variant="h6">{item.size}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          marginTop={5}
          columnSpacing={2}
          style={{ justifyContent: "end" }}
        >
          <Grid item xs={5} lg={3}>
            <Button
              style={{ ...btnPrimaryStyle, width: "100%" }}
              onClick={() => handleOpenAddItem("buy")}
            >
              BUY PRODUCT
            </Button>
          </Grid>
          <Grid item xs={5} lg={3}>
            <Button
              style={{ ...btnPrimaryOutlineStyle, width: "100%" }}
              onClick={() => handleOpenAddItem("cart")}
            >
              ADD TO CART
            </Button>
          </Grid>
          <Grid item xs={2} lg={1}>
            <Button
              style={{ ...btnPrimaryTextStyle, width: "100%" }}
              onClick={handleBookmark}
            >
              {isBookmarked ? <BookmarkIcon /> : <BookmarkBorderOutlinedIcon />}
            </Button>
          </Grid>
        </Grid>

        <Grid
          container
          marginTop={5}
          style={{
            backgroundColor: "#000",
            color: "#fff",
            padding: "40px",
            borderRadius: "15px",
          }}
        >
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="h4">Description</Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="h6">
              {item.description || "Tidak ada deskripsi"}
            </Typography>
          </Grid>
        </Grid>

        <Grid container marginTop={5} marginBottom={5}>
          <Grid item xs={12}>
            <Typography variant="h4" textAlign="center">
              Similar Products
            </Typography>
          </Grid>
          {similarProduct.map((item) => (
            <ItemCard key={item.id} item={item} />
          ))}
          <Grid
            item
            xs={12}
            marginTop={3}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button style={btnPrimaryStyle}>TAP FOR MORE</Button>
          </Grid>
        </Grid>
      </Container>

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={OpenChooseSize}
        onClose={handleCloseChooseSize}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={OpenChooseSize}>
          <Box sx={styleModal}>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  textAlign: "left",
                }}
              >
                Choose Size
              </Typography>
            </Box>

            <List>
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleChooseSize("43")}>
                  <ListItemText primary="43" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleChooseSize("44")}>
                  <ListItemText primary="44" />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openAddItem}
        onClose={handleCloseAddItem}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAddItem}>
          <Box sx={styleModal}>
            <Typography id="spring-modal-title" variant="h6" component="h2">
              {addItemMessage}
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Button
                sx={{ backgroundColor: "#FE8A01", color: "white" }}
                onClick={addItem}
                disabled={loadingAddItem}
              >
                {addItemButton}
              </Button>
              <Button
                variant="outlined"
                onClick={handleCloseAddItem}
                sx={{ ml: 2 }}
              >
                Tidak
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={OpenSuccessSnack}
        autoHideDuration={3000}
        onClose={() => setopenSuccessSnack(false)}
      >
        <SnackbarContent 
          sx={{ backgroundColor: "#19B600" }} 
          action={action}
          message="Berhasil menambahkan ke keranjang"/>
      </Snackbar>
    </>
  );
};

export default ItemDetailPage;

import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "@mui/material/Button";
import axios from "axios";
import {
  Alert,
  Box,
  Grid,
  InputAdornment,
  FormHelperText,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./register.css";
import logoShow from "./assets/EyeClose.svg";
import logoHide from "./assets/EyeOpen.svg";
import calendarLogo from "./assets/calendar-icon.png";

const containerStyle = {
  backgroundColor: "black",
  color: "white",
  borderRadius: 25,
  boxShadow: "10px 10px 5px grey",
};

const textfieldStyle = {
  input: {
    color: "white",
    border: "1px solid white",
    borderRadius: "4px",
  },
  placeholder: {
    color: "lightgray",
  },
};

const btnRegister = {
  marginTop: 5,
  justifyContent: "center",
  borderRadius: "10px",
  backgroundColor: "#FE8A01",
  color: "black",
  border: "3px solid black",
};

function RegisterPage() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const [param, setParam] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  // const [usernameIG, setUsernameIG] = useState('');
  const [phonenumber, setPhonenumber] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    birthdate ? new Date(birthdate) : null
  );
  const [konfirmasiPassword, setKonfirmasiPassword] = useState("");
  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState();
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const orderidFromQuery = params.get("orderid");
  console.log(orderidFromQuery);

  const [showPassword, setShowPassword] = React.useState(false);
  const [showKonfirmasiPassword, setShowKonfirmasiPassword] =
    React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleClickShowKonfirmasiPassword = () => {
    setShowKonfirmasiPassword((show) => !show);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setBirthdate(date ? date.toISOString().split("T")[0] : ""); // Adjust format as needed
  };

  const validate = () => {
    let tempErrors = {};

    // if (!name) {
    //   tempErrors.name = "nama harus diisi";
    // } else if (name.length > 255) {
    //   tempErrors.name = "nama maksimal berjumlah 255 karakter";
    // } else if (typeof name !== "string") {
    //   tempErrors.name = "nama harus berisikan huruf";
    // }
    if (!username) {
      tempErrors.username = "Username harus diisi";
    } else if (username.length > 50) {
      tempErrors.username = "Username maksimal berjumlah 50 karakter";
    }
    if (!password) {
      tempErrors.password = "Password harus diisi";
    } else if (password.length > 255) {
      tempErrors.password = "Password maksimal berjumlah 255 karakter";
    }
    if (!konfirmasiPassword) {
      tempErrors.konfirmasiPassword = "Konfirmasi Password harus diisi";
    } else if (konfirmasiPassword.length > 255) {
      tempErrors.konfirmasiPassword =
        "Konfirmasi Password maksimal berjumlah 255 karakter";
    } else if (password !== konfirmasiPassword) {
      tempErrors.konfirmasiPassword =
        "Konfirmasi Password harus sama dengan password";
    }
    if (!email) {
      tempErrors.email = "Email harus diisi";
    } else if (email.length > 255) {
      tempErrors.email = "Email barang maksimal berjumlah 255 karakter";
    }
    if (!birthdate) {
      tempErrors.birthdate = "Tanggal lahir harus diisi";
    }
    // if (!usernameIG){
    //   tempErrors.usernameIG = 'usernameIG harus diisi';
    // }
    // else if (usernameIG.length > 50) {
    //   tempErrors.usernameIG = 'usernameIG barang maksimal berjumlah 50 karakter';
    // }
    if (!phonenumber) {
      tempErrors.phonenumber = "Phonenumber harus diisi";
    } else if (phonenumber.length < 10 || phonenumber.length > 12) {
      tempErrors.phonenumber = "Phonenumber harus berjumlah antara 10-12 digit";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const formData = new FormData();
        formData.append("username", username);
        formData.append("password", password);
        // formData.append("name", name);
        formData.append("email", email);
        // formData.append('usernameIG', usernameIG);
        formData.append("phonenumber", phonenumber);
        formData.append("birthdate", birthdate);
        console.log([...formData]);

        const response = await axios.post(`${backendUrl}/register`, formData);
        console.log(response.data);

        setUsername("");
        setPassword("");
        // setName("");
        setEmail("");
        setBirthdate("");
        setPhonenumber("");
        // setUsernameIG('');
        setKonfirmasiPassword("");

        localStorage.setItem("berhasilRegister", response.data.message);
        if (orderidFromQuery == null) {
          navigate("/login");
        } else {
          navigate(`/login?orderid=${orderidFromQuery}`);
        }
      } catch (error) {
        console.log(error);
        setShowError(true);
        setMsgError(error.response.data.message);
        setTimeout(() => {
          setShowError(false);
        }, 5000);
      }
    }
  };

  return (
    <Container component="main" maxWidth="sm" sx={{ marginTop: 10 }}>
      {showError && (
        <Alert variant="filled" severity="error" sx={{ marginBottom: 5 }}>
          {msgError}
        </Alert>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 5,
          borderRadius: 5,
        }}
        style={containerStyle}
      >
        <Typography component="h1" variant="h4">
          Register
        </Typography>
        <Box sx={{ display: "flex" }}>
          <Typography>Already have an account?</Typography>&nbsp;&nbsp;&nbsp;
          <Typography sx={{ color: "#FE8A01" }}>
            {param ? (
              // <a
              //   href={`/login?orderid=${orderidFromQuery}`}
              //   style={{ color: "#FE8A01", textDecoration: "none" }}
              // >
              //   Login here
              // </a>
              <Link
                style={{ color: "#FE8A01", textDecoration: "none" }}
                to={`/login?orderid=${orderidFromQuery}`}
              >
                Login here
              </Link>
            ) : (
              <Link
                style={{ color: "#FE8A01", textDecoration: "none" }}
                to={"/login"}
              >
                Login here
              </Link>
            )}
          </Typography>
        </Box>
        <Grid container spacing={3} marginTop={1}>
          <Grid item xs={12}>
            <TextField
              sx={textfieldStyle}
              placeholder="Username"
              value={username}
              helperText={errors.username}
              FormHelperTextProps={{ sx: { color: "red" } }}
              autoComplete="off"
              fullWidth
              onChange={(e) => setUsername(e.target.value)}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <TextField
              sx={textfieldStyle}
              placeholder="Name"
              value={name}
              helperText={errors.name}
              FormHelperTextProps={{ sx: { color: "red" } }}
              autoComplete="off"
              fullWidth
              onChange={(e) => setName(e.target.value)}
            />
          </Grid> */}
          <Grid item xs={12}>
            <TextField
              sx={textfieldStyle}
              type="email"
              placeholder="Email"
              value={email}
              helperText={errors.email}
              FormHelperTextProps={{ sx: { color: "red" } }}
              autoComplete="off"
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              sx={textfieldStyle}
              type="text"
              placeholder="Phone number"
              value={phonenumber}
              helperText={errors.phonenumber}
              FormHelperTextProps={{ sx: { color: "red" } }}
              autoComplete="off"
              fullWidth
              onChange={(e) => setPhonenumber(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <div className="datepicker-wrapper" style={{ width: "100%" }}>
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                placeholderText="Birthdate"
                dateFormat="dd/MM/yyyy"
                className="custom-datepicker"
                autoComplete="off"
                popperPlacement="bottom"
              />
              <img src={calendarLogo} alt="calendar" className="calendar-icon"></img>
            </div>
            {errors.birthdate && (
              <FormHelperText sx={{ color: "red" }}>
                {errors.birthdate}
              </FormHelperText>
            )}
          </Grid>
          {/* <Grid item xs={12}>
            <TextField
              sx={textfieldStyle}
              type="date"
              placeholder="Birthdate "
              helperText={errors.birthdate}
              FormHelperTextProps={{ sx: { color: "red" } }}
              value={birthdate}
              autoComplete="off"
              fullWidth
              onChange={(e) => setBirthdate(e.target.value)}
            />
          </Grid> */}
          {/* <Grid item xs={12}>
            <TextField
              sx={textfieldStyle}
              placeholder="UsernameIG"
              value={usernameIG}
              helperText={errors.usernameIG}
              FormHelperTextProps={{ sx: { color: 'red' } }}
              autoComplete='off'
              fullWidth
              onChange={(e) => setUsernameIG(e.target.value)}
            />
          </Grid> */}
          <Grid item xs={12}>
            {/* <TextField
              sx={textfieldStyle}
              placeholder="Password"
              type="password"
              value={password}
              helperText={errors.password}
              FormHelperTextProps={{ sx: { color: "red" } }}
              fullWidth
              onChange={(e) => setPassword(e.target.value)}
            /> */}

            <TextField
              sx={textfieldStyle}
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              // type="password"
              value={password}
              helperText={errors.password}
              FormHelperTextProps={{ sx: { color: "red" } }}
              fullWidth
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <div
                      onClick={handleClickShowPassword}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={showPassword ? logoHide : logoShow}
                        alt="Toggle visibility"
                        style={{ width: "24px", height: "24px" }}
                      />
                    </div>
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              sx={textfieldStyle}
              placeholder="Konfirmasi Password"
              type={showKonfirmasiPassword ? "text" : "password"}
              // type="password"
              value={konfirmasiPassword}
              helperText={errors.konfirmasiPassword}
              FormHelperTextProps={{ sx: { color: "red" } }}
              fullWidth
              onChange={(e) => setKonfirmasiPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <div
                      onClick={handleClickShowKonfirmasiPassword}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={showKonfirmasiPassword ? logoHide : logoShow}
                        alt="Toggle visibility"
                        style={{ width: "24px", height: "24px" }}
                      />
                    </div>
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12}>
            <Button style={btnRegister} fullWidth onClick={handleSubmit}>
              Register
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default RegisterPage;

import axios from "axios";

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const cleanedBackendUrl = backendUrl.replace("/backend", "");

const CartService = {
  getCartList(customerId) {
    return axios.get(`${cleanedBackendUrl}/cart?customerId=${customerId}`);
  },
  changeCartStatus(cartId, checked) {
    return axios.post(`${cleanedBackendUrl}/cart/update`, {
      cartId: cartId,
      checked: checked,
    });
  },
  deleteCart(cartId) {
    return axios.delete(`${cleanedBackendUrl}/cart`, {
      data: {
        cartId: cartId,
      },
    });
  },
  addToCart(customerId, itemId) {
    return axios.post(`${cleanedBackendUrl}/cart`, {
      customerId: customerId,
      itemId: itemId,
    });
  },
	proceedToPayment(cartIds) {
		return axios.post(`${cleanedBackendUrl}/cart/proceed`, {
			cartIds: cartIds
		})
	} 
};

export default CartService;

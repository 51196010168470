import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LocalMallIcon from '@mui/icons-material/LocalMall';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MoreIcon from "@mui/icons-material/MoreVert";
import {
  Backdrop,
  Container,
  Fade,
  Grid,
  Modal,
  Button,
  Tabs,
  Tab,
  Alert,
  Tooltip,
} from "@mui/material";

import axios from "axios";
import { useAuth } from "../authContext";
import logoToko from "../assets/logo_copy.png";
import { NumericFormat } from "react-number-format";
import Navigation from "./navigation";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(3),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(2),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "black",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "black",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const btnPaymentStyle = {
  borderRadius: "10px",
  width: "20vw",
  backgroundColor: "#FE8A01",
  color: "black",
  border: "1px solid black",
  padding: 5,
  marginLeft: 25,
  textTransform: "none",
};

const btnBackStyle = {
  borderRadius: "10px",
  color: "white",
  width: "10vw",
  backgroundColor: "black",
  border: "1px solid white",
  padding: 5,
  textTransform: "none",
};

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxHeight: "80vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  textAlign: "center",
  overflowY: "auto",
  overflowX: "hidden",
};

const tabStyle = {
  "&.Mui-selected": {
    color: "white",
    backgroundColor: "#FE8A01",
    color: "white",
    variant: "h6",
  },
  "&:hover": {
    color: "white",
    backgroundColor: "#FE8A01",
    color: "white",
    variant: "h6",
  },
  backgroundColor: "white",
  color: "black", 
};

const styleModalLogout = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: "80vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  textAlign: "center",
  overflowY: "auto",
};

export default function HistoryPage() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const cleanedBackendUrl = backendUrl.replace("/backend", "");
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [DataCust, setDataCust] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isLoadingOrder, setIsLoadingOrder] = useState(true);
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const [errors, setErrors] = useState("");
  const [ShowSuccess, setShowSuccess] = useState(false);
  const [MsgSuccess, setMsgSuccess] = useState("");
  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState();
  const [openLogout, setOpenLogout] = useState(false);
  const handleOpenLogout = () => setOpenLogout(true);
  const handleCloseLogout = () => setOpenLogout(false);
  const { auth, logout } = useAuth();
  const getUsername = auth.user ? auth.user.username : "";
  const getId = auth.user ? auth.user.id : "";
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const storedOrderId = localStorage.getItem("orderid");
  const [value, setValue] = useState(0);
  const [orderData, setOrderData] = useState({});
  const navigate = useNavigate();

  const handleRowClick = (id, status) => {
    if (status?.key === "DRAFT" || status?.key === "WAITING_FOR_PAYMENT") {
      navigate(`/customer/checkoutPage/${id}`);
    } else {
      setMsgError("Order is finished.");
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 3000);
    }
  };

  const handleNavigateHistory = () => {
    navigate('/customer/historyPage');
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

// Handle Tab Change
const handleChange = (event, newValue) => {
  setValue(newValue);
};

const fetchDataOrderbyPhone = async () => {
  setIsLoadingOrder(true); // Set loading state to true
  try {
    const response = await axios.get(
      `${cleanedBackendUrl}/orders?phone-number=${DataCust.phonenumber}`
    );
    console.log(response);
    setOrderData(response.data || {});
    console.log(orderData)
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setIsLoadingOrder(false); // Set loading state to false
  }
};

// Data Destructuring from props
const {
  orderList,
  canceledList,
  waitingForPaymentList,
  paidList,
  packagedList,
  onDeliveryList,
  deliveredList,
} = orderData;

// TabPanel Component: Handles displaying content of each tab
function TabPanel({ children, value, index }) {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

// OrdersTable Component: Displays a table with order data
function OrdersTable({ data, message }) {
  if (data?.length === 0) {
    return <div>{message}</div>;
  }

  return (
    <table border="1" style={{ width: "100%", borderCollapse: "collapse" }}>
      <thead>
        <tr>
          <th>ID</th>
          <th>Order Item</th>
          <th>Order Date</th>
          <th>Phone Number</th>
          <th>Status</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((order) => (
          <tr
            key={order.id}
            onClick={() => handleRowClick(order.id, order.status)}
            style={{ cursor: "pointer" }}
          >
            <td>{order.id}</td>
            <td>
              {order.itemList?.map((item) => (
                <td key={item.id} sx={{ border: "none" }}>
                  {item?.name} - {item?.typeName} ({item?.typeVariant})
                </td>
              ))}
            </td>
            <td>{order.updatedDate}</td>
            <td>{order.phoneNumber}</td>
            <td>{order.status?.label}</td> {/* Handles status null check */}
            <td>{formatCurrency(order.price)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

  const fetchDataCust = async () => {
    setDataCust(auth.user);
  };

  useEffect(() => {
    fetchDataCust();
  }, []);

  useEffect(() => {
    console.log("Data Customer:", DataCust);
    fetchDataOrderbyPhone();
  }, [DataCust]);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    localStorage.removeItem("orderid");
    setOpenLogout(false);
    logout();
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      style={{ backgroundColor: "#FE8A01" }}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <Navigation />

      <Container component="main">
        {ShowSuccess && (
          <Alert variant="filled" severity="success" style={{ marginTop: 20 }}>
            {MsgSuccess}
          </Alert>
        )}
        {showError && (
          <Alert variant="filled" severity="error" style={{ marginTop: 20 }}>
            {msgError}
          </Alert>
        )}
        <Box
          sx={{
            marginTop: 10,
            display: "flex",
            flexDirection: "column",
            borderRadius: 3,
            boxShadow: "10px 10px 5px gray",
            backgroundColor: "black",
            height: "auto",
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={12} sx={{ position: "relative" }}>
              <Box
                sx={{ backgroundColor: "#FE8A01", height: "auto", padding: 5 }}
              >
                <Typography variant="h3" fontWeight={"bolder"}>
                  History
                </Typography>
              </Box>
              <img
                src={logoToko}
                alt="Lique Store"
                style={{ width: 500, position: "absolute", top: 60, zIndex: 1 }}
              />
            </Grid>
            <Grid item xs={12} marginTop={10}>
              <Box sx={{ padding: 2 }}>
                <Box sx={{ padding: 2, margin: 4, color: "white" }}>
                  <Typography variant="h4">Transaction History</Typography>
                  <Grid container spacing={3} marginTop={3}>
                    {}
                  </Grid>

                  <Box>
                    {/* Tab Headers */}
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      variant="scrollable" // Enable scrollable behavior
                      scrollButtons="auto" // Show scroll buttons if tabs overflow
                      allowScrollButtonsMobile // Allow scroll buttons to appear on mobile
                      sx={{
                        "& .MuiTabs-scroller": {
                          overflowY: "hidden", // Disable vertical scrolling
                          overflowX: "auto", // Enable horizontal scrolling only
                        },
                        "& .MuiTabs-flexContainer": {
                          flexWrap: "nowrap", // Prevent tabs from wrapping into multiple lines
                        },
                        "& .MuiTab-root": {
                          minWidth: "120px", // Adjust tab width if necessary
                          whiteSpace: "nowrap", // Prevent text wrapping in tab headers
                        },
                      }}
                    >
                      <Tab
                        label={`Orders (${orderList?.length || 0})`}
                        sx={tabStyle}
                      />
                      <Tab
                        label={`Canceled Orders (${canceledList?.length || 0})`}
                        sx={tabStyle}
                      />
                      <Tab
                        label={`Waiting for Payment (${
                          waitingForPaymentList?.length || 0
                        })`}
                        sx={tabStyle}
                      />
                      <Tab
                        label={`Paid Orders (${paidList?.length || 0})`}
                        sx={tabStyle}
                      />
                      <Tab
                        label={`Packaged Orders (${packagedList?.length || 0})`}
                        sx={tabStyle}
                      />
                      <Tab
                        label={`On Delivery (${onDeliveryList?.length || 0})`}
                        sx={tabStyle}
                      />
                      <Tab
                        label={`Delivered (${deliveredList?.length || 0})`}
                        sx={tabStyle}
                      />
                    </Tabs>

                    {/* Tab Panels */}
                    <TabPanel value={value} index={0}>
                      <OrdersTable data={orderList} message="No Orders" />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <OrdersTable
                        data={canceledList}
                        message="No Canceled Orders"
                      />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      <OrdersTable
                        data={waitingForPaymentList}
                        message="No Orders Waiting for Payment"
                      />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                      <OrdersTable data={paidList} message="No Paid Orders" />
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                      <OrdersTable
                        data={packagedList}
                        message="No Packaged Orders"
                      />
                    </TabPanel>
                    <TabPanel value={value} index={6}>
                      <OrdersTable
                        data={onDeliveryList}
                        message="No Orders on Delivery"
                      />
                    </TabPanel>
                    <TabPanel value={value} index={7}>
                      <OrdersTable
                        data={deliveredList}
                        message="No Delivered Orders"
                      />
                    </TabPanel>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import axios from "axios";
import styled from "styled-components";
import {
  Alert,
  Autocomplete,
  Backdrop,
  Button,
  CssBaseline,
  darken,
  Drawer,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  Modal,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import SupervisorSidebar from "./sidebar";
import { useSpring, animated } from "@react-spring/web";
import { AccountCircle } from "@mui/icons-material";
import { useAuth } from "../authContext";
import { NumericFormat } from "react-number-format";

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

export default function Live() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const cleanedBackendUrl = backendUrl.replace("/backend", "");
  const [page, setPage] = useState("main");
  const [submitStatus, setSubmitStatus] = useState(false);
  const [ShowSuccess, setShowSuccess] = useState(false);
  // const [CekKode, setCekKode] = useState(false);
  const [MsgInsert, setMsgInsert] = useState("");
  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState();
  const [ColourData, setColourData] = useState([]);
  const [DataTipe, setDataTipe] = useState([]);
  const [selectedColour, setSelectedColour] = useState([]);
  const [Colourcode, setColourcode] = useState([]);
  const [Colourhex, setColourhex] = useState([]);
  const [TypeCode, setTypeCode] = useState([]);
  const [TypeName, setTypeName] = useState([]);
  const [TypeVariant, setTypeVariant] = useState([]);
  const [Orderid, setOrderid] = useState([]);
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [prices, setPrices] = useState([]);
  const [status, setStatus] = useState("");
  const [buttonClicked, setButtonClicked] = useState("");
  const [TotalPrice, setTotalPrice] = useState("");
  const [checkoutLink, setCheckoutLink] = useState([]);
  const [errors, setErrors] = useState({});
  const [showLink, setShowLink] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);
  const handleOpenLogout = () => setOpenLogout(true);
  const handleCloseLogout = () => setOpenLogout(false);
  const { auth, logout } = useAuth();
  const getusername = auth.user ? auth.user.username : "";
  const [itemsCount, setItemsCount] = useState(0);

  const toDetail = () => {
    // Validate that each field has a value
    if (
      Colourcode.length > 0 &&
      Colourcode.every(
        (colour) => colour !== "" && colour !== undefined && colour !== null
      ) && // Check if Colourcode has valid values
      Orderid.length > 0 &&
      Orderid.every((id) => id !== "" && id !== undefined && id !== null) && // Check if Orderid has valid values
      PhoneNumber !== "" &&
      PhoneNumber !== undefined &&
      PhoneNumber !== null && // Check if PhoneNumber is valid
      TotalPrice !== "" &&
      TotalPrice !== undefined &&
      TotalPrice !== null && // Check if TotalPrice is valid
      TypeCode.length > 0 &&
      TypeCode.every(
        (code) => code !== "" && code !== undefined && code !== null
      ) // Check if TypeCode has valid values
    ) {
      setPage("detail");
    } else {
      alert("Please make sure all required fields are filled.");
    }
  };

  const toMain = () => {
    setPage("main");
  };

  const validate = () => {
    let tempErrors = {};

    // // Validasi Orderid
    if (Orderid.length == 0) {
      tempErrors.Orderid = "Kode pesanan harus diisi";
    }

    // // Validasi Capital Price
    if (PhoneNumber == "") {
      tempErrors.PhoneNumber = "Nomor wa harus diisi";
    }

    // Validasi Default Price
    if (TotalPrice == "") {
      tempErrors.TotalPrice = "Harga barang harus diisi";
    }

    if (TypeCode.length == 0) {
      tempErrors.TotalPrice = "Kode harus diisi";
    }

    if (selectedColour.length == 0) {
      tempErrors.TotalPrice = "Kode harus diisi";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const fetchDataType = async () => {
    try {
      const response = await axios.get(`${cleanedBackendUrl}/items/types`);
      console.log(response.data.itemTypeList);
      setDataTipe(response.data.itemTypeList);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataColour = async () => {
    try {
      const response = await axios.get(`${cleanedBackendUrl}/orders/colors`);
      console.log(response.data.orderColorList);
      setColourData(response.data.orderColorList);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchDataType();
    fetchDataColour();
  }, []);

  const optColour = ColourData.map((item) => ({
    label: item.name,
    value: item.id,
    colourcode: item.code,
    colourhex: item.hexCode,
  }));

  const optTipe = DataTipe.map((item) => ({
    id: item.id,
    name: item.name,
    varian: item.variant,
    value: item.code,
  }));

  const handleSelectColor = (idX, idY) => {
    setSelectedColour((prevSelectedColour) => {
      const updatedColour = [...prevSelectedColour]; // Create a shallow copy of the array
      updatedColour[idX] = idY; // Set the value at the specific index
      return updatedColour;
      // Update the state with the new array
    });
    setColourcode((prevColourcode) => {
      const updatedColourcode = [...prevColourcode]; // Create a shallow copy of the array

      if (optColour[idY]) {
        // Check if the index idY exists in optColour
        updatedColourcode[idX] = optColour[idY].colourcode; // Set the colourcode at idX
      }

      return updatedColourcode; // Update the state with the new array
    });
    setColourhex((prevColourcode) => {
      const updatedColourhex = [...prevColourcode]; // Create a shallow copy of the array

      if (optColour[idY]) {
        // Check if the index idY exists in optColour
        updatedColourhex[idX] = optColour[idY].colourhex; // Set the colourcode at idX
      }

      return updatedColourhex; // Update the state with the new array
    });
  };

  const handleOrderidChange = (e, idX) => {
    const value = e.target.value;

    if (/^\d*$/.test(value)) {
      setOrderid((prevOrderid) => {
        const updatedOrderid = [...prevOrderid]; // Create a shallow copy of the array
        updatedOrderid[idX] = value; // Set the value at the specific index
        return updatedOrderid; // Update the state with the new array
      });
    }
  };

  const handlePriceChange = (values, indexX) => {
    const { floatValue } = values; // Extract the float value from NumericFormat

    setPrices((prevPrices) => {
      const updatedPrices = [...prevPrices]; // Create a shallow copy of the array
      updatedPrices[indexX] = floatValue || 0; // Update the specific price

      let TotalPrice = 0; // Declare TotalPrice with let so it can be modified
      for (let i = 0; i < updatedPrices.length; i++) {
        TotalPrice += parseFloat(updatedPrices[i]) || 0; // Sum all prices, handle NaN
      }

      console.log(TotalPrice);
      setTotalPrice(TotalPrice); // Update the total price

      // Return the updated prices array to update the state
      return updatedPrices;
    });
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;

    // Allow only numeric characters
    if (/^\d*$/.test(value)) {
      setPhoneNumber(value);
    }
  };

  const handleAutocompleteTypeChange = (event, newValue, indexX) => {
    // Update TypeCode state
    setTypeCode((prevTypeCodes) => {
      const updatedTypeCodes = [...prevTypeCodes];
      updatedTypeCodes[indexX] = newValue ? newValue.value : null; // Set the value at the specific index
      return updatedTypeCodes;
    });

    // Update TypeName state
    setTypeName((prevTypeNames) => {
      const updatedTypeNames = [...prevTypeNames];
      updatedTypeNames[indexX] = newValue ? newValue.name : null; // Set the option.name at the specific index
      return updatedTypeNames;
    });

    setTypeVariant((prevTypeNames) => {
      const updatedTypeVariant = [...prevTypeNames];
      updatedTypeVariant[indexX] = newValue ? newValue.varian : null; // Set the option.name at the specific index
      return updatedTypeVariant;
    });
  };

  useEffect(() => {
    if (buttonClicked === "draft") {
      handleSubmit();
    } else if (buttonClicked === "submit") {
      handleSubmit();
    }
  }, [status]);

  const handleClick = (button) => {
    setStatus(button === "submit" ? "WAITING_FOR_PAYMENT" : "DRAFT");
    setButtonClicked(button); // Update buttonClicked state
  };

  const handleSubmit = async () => {
    if (validate()) {
      console.log(Colourcode);
      console.log(Orderid);
      console.log(PhoneNumber);
      console.log(prices);
      console.log(TypeCode);
      // try {
      console.log(itemsCount); // Adjust as needed
      console.log(status);

      const orderList = [];

      for (let i = 0; i <= itemsCount; i++) {
        const order = {
          colorCode: Colourcode[i],
          orderNumber: Orderid[i],
          phoneNumber: PhoneNumber, // Assuming PhoneNumber is an array
          itemList: [
            {
              typeCode: TypeCode[i],
            },
          ],
          price: prices[i],
          status: status,
        };

        orderList.push(order);
      }
      const postData = { orderList };
      console.log(postData);
      try {
        const response = await axios.post(
          `${cleanedBackendUrl}/orders/batch`,
          postData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        // If the request is successful, log the response
        console.log("Response:", response);
        setSubmitStatus(true);
        // You can also access and log specific data from the response if needed
        const orderId = response.data.outputSchema.batchOrderLink;
        console.log("Order ID:", orderId);

        setShowSuccess(true);
        setMsgInsert("Berhasil Menambah Pesanan");

        const newLink = orderId;

        setCheckoutLink((prevLinks) => {
          const updatedLinks = [...prevLinks];
          updatedLinks[0] = newLink;
          return updatedLinks;
        });

        setTimeout(() => {
          setShowSuccess(false);
        }, 5000);
      } catch (error) {
        // Handle errors here
        setShowError(true);
        setSubmitStatus(false);
        console.error("Error creating order:", error);

        // If you need to handle specific error types
        if (error.response) {
          // Server responded with a status other than 2xx
          console.error("Server Error:", error.response.data);
        } else if (error.request) {
          // Request was made but no response was received
          console.error("No response received:", error.request);
        } else {
          // Something else happened in making the request that triggered an error
          console.error("Error in setup:", error.message);
        }

        // if (error.response.data === "Kode pemesanan sudah digunakan") {
        //   setMsgError("Kode pemesanan sudah digunakan");
        // } else {
        //   setMsgError("Gagal Menambah Pemesanan");
        // }
        setTimeout(() => {
          setShowError(false);
        }, 5000);
      }
      // }
      // setOrderid([]);
      // setTypeCode("");
      // setPhoneNumber("");
      // setTotalPrice("");
      // setShowLink(true);
      // } catch (error) {
      //   // console.log(error.response.data);
      //   console.error("Error response:", error.response);
      // }
    } else {
      console.log("Validation failed");
    }
  };

  const resetForm = () => {
    setColourcode([]); // Reset Colourcode to an empty array
    setOrderid([]); // Reset Orderid to an empty array
    setPhoneNumber(""); // Reset PhoneNumber to an empty string
    setPrices([]); // Reset prices to an empty array
    setTypeCode([]);
    setSelectedColour([]); // Reset TypeCode to an empty array
    setItemsCount(0); // Reset itemsCount to 0
    setSubmitStatus(false);
    setStatus("");
    setCheckoutLink([]);
    setButtonClicked("");
  };

  const handleLogout = () => {
    setOpenLogout(false);
    logout();
  };

  const handleAddItem = () => {
    setItemsCount(itemsCount + 1);
  };

  const handleRemoveItem = () => {
    if (itemsCount != 0) {
      setItemsCount(itemsCount - 1);
    }
  };

  const handleCopy = (link) => {
    navigator.clipboard.writeText(link);
    setCopySuccess(true);

    // Hide the notification after 3 seconds
    setTimeout(() => {
      setCopySuccess(false);
    }, 3000);
  };

  const drawerWidth = 300;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "black",
              color: "white",
            },
          }}
          open
        >
          <SupervisorSidebar />
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Button
          style={{ float: "right" }}
          color="inherit"
          onClick={handleOpenLogout}
          startIcon={<AccountCircle />}
        >
          {getusername}
        </Button>
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={openLogout}
          onClose={handleCloseLogout}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              TransitionComponent: Fade,
            },
          }}
        >
          <Fade in={openLogout}>
            <Box sx={styleModal}>
              <AccountCircle style={{ fontSize: 100 }} />
              <Typography id="spring-modal-title" variant="h6" component="h2">
                Apakah anda yakin ingin keluar?
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Button variant="outlined" onClick={handleLogout}>
                  Ya
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleCloseLogout}
                  sx={{ ml: 2, backgroundColor: "#FE8A01", color: "white" }}
                >
                  Tidak
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
        <br></br>
        <Toolbar />
        <RootContainer>
          {ShowSuccess && (
            <Alert
              variant="filled"
              severity="success"
              style={{ marginBottom: 3 }}
            >
              {MsgInsert}
            </Alert>
          )}
          {showError && (
            <Alert
              variant="filled"
              severity="error"
              style={{ marginBottom: 3 }}
            >
              {msgError}
            </Alert>
          )}
          <Typography variant="h3" marginBottom={5}>
            Input Pesanan live
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>Nomor WA *</Typography>
              <TextField
                fullWidth
                type="text"
                autoComplete="off"
                value={PhoneNumber}
                error={!!errors.PhoneNumber}
                helperText={errors.PhoneNumber}
                FormHelperTextProps={{ sx: { color: "red" } }}
                onChange={(e) => handlePhoneNumberChange(e)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>Jumlah Barang: {itemsCount + 1}</Typography>
            </Grid>
          </Grid>
          {page === "main" ? (
            <FormControl
              sx={{
                width: "100%",
              }}
            >
              {Array(itemsCount + 1)
                .fill()
                .map((_, indexX) => (
                  <Grid container spacing={2}>
                    <Grid item xs={1}>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          marginTop: "32px",
                        }}
                      >
                        ITEM {indexX + 1}
                      </Typography>
                    </Grid>

                    {/* <Grid item xs={11}
                    sx={{
                      cursor: 'pointer'
                    }}>
                    <KeyboardArrowDown></KeyboardArrowDown>
                  </Grid> */}

                    <Grid item xs={12}>
                      <Typography>Kode Warna *</Typography>
                    </Grid>
                    {Array(optColour.length)
                      .fill()
                      .map((_, indexY) => (
                        <Grid item xs={1}>
                          <Box
                            sx={{
                              width: 36,
                              height: 36,
                              borderRadius: "2px",
                              bgcolor: optColour[indexY].colourhex,
                              outline:
                                selectedColour[indexX] === indexY
                                  ? "4px solid rgb(254, 138, 1)"
                                  : "2px solid black",
                              outlineOffset: "4px",
                              "&:hover": {
                                bgcolor: darken(
                                  optColour[indexY].colourhex,
                                  0.2
                                ),
                              },
                            }}
                            onClick={() => handleSelectColor(indexX, indexY)}
                          ></Box>
                        </Grid>
                      ))}
                    <Grid item xs={12}>
                      <Typography>Nomor Barang *</Typography>
                      <TextField
                        fullWidth
                        type="text"
                        autoComplete="off"
                        error={!!errors.Orderid}
                        helperText={errors.Orderid}
                        FormHelperTextProps={{ sx: { color: "red" } }}
                        value={Orderid[indexX] || ""} // Make sure to handle undefined values gracefully
                        onChange={(e) => handleOrderidChange(e, indexX)}
                        // inputProps={{
                        //   maxLength: 3, // Limit input to 3 characters
                        // }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {/* <Typography>Kode Barang</Typography>
                      <Autocomplete
                        fullWidth
                        options={optTipe}
                        getOptionLabel={(option) =>
                          option.name + " - " + option.varian
                        }
                        getOptionSelected={(option, value) =>
                          option.value === value
                        }
                        renderInput={(params) => <TextField {...params} />}
                        value={optTipe.find(
                          (option) => option.value === TypeCode
                        )}
                        error={!!errors.TypeCode}
                        // onChange={handleAutocompleteTypeChange}
                      /> */}
                      <Grid item xs={12}>
                        <Typography>Kode Barang *</Typography>
                        <Autocomplete
                          fullWidth
                          options={optTipe}
                          getOptionLabel={(option) =>
                            option.name + " - " + option.varian
                          }
                          renderInput={(params) => <TextField {...params} />}
                          value={
                            optTipe.find(
                              (option) => option.value === TypeCode[indexX]
                            ) || null
                          }
                          onChange={(event, newValue) =>
                            handleAutocompleteTypeChange(
                              event,
                              newValue,
                              indexX
                            )
                          }
                          error={!!errors.TypeCode}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>Harga *</Typography>
                      <NumericFormat
                        fullWidth
                        type="text"
                        value={prices[indexX] || ""} // Handle undefined values gracefully
                        onValueChange={(values) =>
                          handlePriceChange(values, indexX)
                        }
                        thousandSeparator="."
                        decimalSeparator=","
                        customInput={TextField}
                        error={!!errors.TotalPrice}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">Rp</InputAdornment>
                          ),
                        }}
                        variant="outlined"
                      />
                      {!!errors.TotalPrice && (
                        <FormHelperText error sx={{ color: "red" }}>
                          {errors.TotalPrice}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                ))}

              {!submitStatus && (
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      // color: "#FE8A01",
                      textAlign: "left",
                      textDecoration: "none",
                    }}
                    style={{
                      marginTop: "40px",
                    }}
                  >
                    <Typography
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={handleRemoveItem}
                    >
                      - Remove Item
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      // color: "#FE8A01",
                      textAlign: "right",
                      textDecoration: "none",
                    }}
                    style={{
                      marginTop: "40px",
                    }}
                  >
                    <Typography
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={handleAddItem}
                    >
                      + Add More Item
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={toDetail}
                      fullWidth
                      style={{
                        backgroundColor: "grey",
                        color: "white",
                        border: "solid 2px black",
                      }}
                    >
                      Ringkasan Order
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default behavior right here  // Call the first function
                        handleClick("draft"); // Call the second function with the event object
                      }}
                      fullWidth
                      style={{
                        backgroundColor: "grey",
                        color: "white",
                        border: "solid 2px black",
                      }}
                    >
                      Draft
                    </Button>
                  </Grid>
                  <Grid item xs={8}>
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default behavior right here  // Call the first function
                        handleClick("submit"); // Call the second function with the event object
                      }}
                      fullWidth
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        border: "solid 2px black",
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              )}

              <Grid
                container
                spacing={3}
                sx={{
                  marginTop: "24px",
                }}
              >
                {checkoutLink.map((link, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        onClick={toDetail}
                        fullWidth
                        style={{
                          backgroundColor: "grey",
                          color: "white",
                          border: "solid 2px black",
                        }}
                      >
                        Ringkasan Order
                      </Button>
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          resetForm(); // Call the reset function
                        }}
                        fullWidth
                        style={{
                          backgroundColor: "grey",
                          color: "white",
                          border: "solid 2px black",
                        }}
                      >
                        New Order
                      </Button>
                    </Grid>

                    <Grid item xs={10}>
                      <Typography
                        sx={{
                          width: "100%",
                          height: "auto",
                          minHeight: "40px",
                          border: "2px solid grey",
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: "8px",
                          borderRadius: "4px",
                          color: "grey",
                        }}
                      >
                        {link}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        variant="contained"
                        fullWidth
                        style={{
                          backgroundColor: "rgb(254, 138, 1)",
                          color: "white",
                          border: "solid 2px black",
                        }}
                        onClick={() => handleCopy(link)}
                      >
                        Copy
                      </Button>
                    </Grid>
                  </React.Fragment>
                ))}
                <Snackbar
                  open={copySuccess}
                  message="Link copied to clipboard!"
                  autoHideDuration={3000}
                />
              </Grid>
            </FormControl>
          ) : (
            <FormControl
              sx={{
                width: "100%",
              }}
            >
              {Array(itemsCount + 1)
                .fill()
                .map((_, index) => (
                  <Grid
                    container
                    spacing={3}
                    sx={{
                      width: "100%",
                      marginTop: "32px",
                    }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontWeight: "600",
                        }}
                      >
                        ITEM {index + 1}
                      </Typography>
                    </Grid>

                    {/* <Grid item xs={11}
                    sx={{
                      cursor: 'pointer'
                    }}>
                    <KeyboardArrowDown></KeyboardArrowDown>
                  </Grid> */}

                    <Grid
                      item
                      xs={2}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          borderRadius: "2px",
                          width: 36,
                          height: 36,
                          bgcolor: Colourhex[index],
                          outline: "2px solid black",
                          outlineOffset: "4px",
                        }}
                      ></Box>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography>
                        {TypeCode[index] +
                          "(" +
                          TypeName[index] +
                          " - " +
                          TypeVariant[index] +
                          ")" +
                          " - " +
                          Orderid[index]}
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <React.Fragment>
                        <NumericFormat
                          sx={{
                            marginTop: "32px",
                          }}
                          value={prices[index]}
                          displayType={"text"}
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix={"Rp. "}
                        />
                      </React.Fragment>
                    </Grid>
                  </Grid>
                ))}
              <Grid item xs={12}>
                <Typography
                  sx={{
                    marginTop: "32px",
                  }}
                >
                  Total harga:
                </Typography>
                <React.Fragment>
                  <NumericFormat
                    sx={{
                      marginTop: "32px",
                    }}
                    value={TotalPrice}
                    displayType={"text"}
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix={"Rp. "}
                  />
                </React.Fragment>
              </Grid>
              <Grid
                container
                spacing={3}
                sx={{
                  marginTop: "4px",
                }}
              >
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    onClick={toMain}
                    fullWidth
                    style={{
                      backgroundColor: "grey",
                      color: "white",
                      border: "solid 2px black",
                    }}
                  >
                    Back
                  </Button>
                </Grid>
              </Grid>
            </FormControl>
          )}
        </RootContainer>
      </Box>
    </Box>
  );
}

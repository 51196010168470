import { Grid, Typography } from "@mui/material"
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import './itemCard.css'
import noImage from "../../../assets/no-image.png";


const ItemCard = ({item}) => {
  const navigate = useNavigate()
  const iamgeUrl = "https://api.liquestore.com/assets/items/"

  return (
    <Grid item xs={6} md={4} lg={3} style={{ backgroundColor: '#fff', overflow: 'visible', padding: '5px' }}>
      <div className="item-card" onClick={() => navigate(`/item/${item.id}`)} style={{ cursor: 'pointer' }}>
        <Grid container>
          <Grid item xs={12} style={{ aspectRatio: "4 / 3" }}>
            <img 
              src={item.images == null ? noImage : `${iamgeUrl}${item.images[0]}`}
              alt={item.name}
              style={{ width: "100%", aspectRatio: "4 / 3", objectFit: "cover" }}
            ></img>
          </Grid>
          <Grid item xs={12} marginTop={1}>
            <Typography variant="h5">{item.name}</Typography>
          </Grid>
          <Grid item xs={12} marginTop={1}>
            <Typography variant="h5" color={"#FE8A01"}>
              <NumericFormat
                value={item.defaultPrice}
                displayType={"text"}
                thousandSeparator="."
                decimalSeparator=","
                prefix={"Rp. "}
              />
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}

export default ItemCard
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Navigation from "./navigation";
import { Alert, Button, Container, Grid, Typography } from "@mui/material";
import CustomerService from "../services/customer/customerService";
import ItemCard from "./components/itemCard/itemCard";

const MoreItemsPage = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const cleanedBackendUrl = backendUrl.replace("/backend", "");
  const [ShowSuccess, setShowSuccess] = useState(false);
  const [MsgSuccess, setMsgSuccess] = useState("");
  const [showError, setShowError] = useState(false);
  const [msgError, setMsgError] = useState();
  const [itemTypeList, setItemTypeList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [shownItem, setShownItem] = useState([]);
  const [title, setTitle] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const navigate = useNavigate()
  const {tag} = useParams();

  const getItemTypeList = async () => {
    try {
      const response = await CustomerService.getItemTypeList()
      if (response) {
        const data = response.data
        setItemTypeList(data.itemTypeList)
      }
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  }

  async function fetchItems() {
    try {
      const responseItemList = await CustomerService.getListItem(tag)
      setItemList(responseItemList.data.itemList)
      setShownItem(responseItemList.data.itemList)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    console.log(tag)
    window.scrollTo(0, 0)
    fetchItems()
    getItemTypeList()

    if (tag === 'new-release') {
      setTitle('NEW RELEASE')
    } else if (tag === 'hot-sale') {
      setTitle('HOT SALE')
    } else if (tag === 'products') {
      setTitle('PRODUCTS')
    }
  }, [])

  useEffect(() => {
    if (selectedType === '') {
      setShownItem(itemList)
    } else {
      setShownItem(itemList.filter(item => item.typeId === selectedType))
    }
  }, [selectedType])

  const btnPrimaryStyle = {
    borderRadius: "100px",
    width: "100%",
    backgroundColor: "#FE8A01",
    color: "white",
    padding: "5px"
  };

  const btnPrimaryOutlineStyle = {
    borderRadius: "100px",
    width: "100%",
    backgroundColor: "transparent",
    border: "1px solid #FE8A01",
    color: "#FE8A01",
    padding: "5px"
  };

  const images = [
    "https://static.nike.com/a/images/t_default/60d2e87c-9eaa-46a0-b9aa-0f730291262b/AIR+FORCE+1+%2707.png",
    "https://static.nike.com/a/images/t_PDP_936_v1/f_auto,q_auto:eco/b7d9211c-26e7-431a-ac24-b0540fb3c00f/AIR+FORCE+1+%2707.png",
    "https://static.nike.com/a/images/t_default/60d2e87c-9eaa-46a0-b9aa-0f730291262b/AIR+FORCE+1+%2707.png",
    "https://static.nike.com/a/images/t_PDP_936_v1/f_auto,q_auto:eco/b7d9211c-26e7-431a-ac24-b0540fb3c00f/AIR+FORCE+1+%2707.png",
    "https://static.nike.com/a/images/t_default/60d2e87c-9eaa-46a0-b9aa-0f730291262b/AIR+FORCE+1+%2707.png",
    "https://static.nike.com/a/images/t_PDP_936_v1/f_auto,q_auto:eco/b7d9211c-26e7-431a-ac24-b0540fb3c00f/AIR+FORCE+1+%2707.png",
  ];

  return (
    <>
      <Navigation />

      <Container component="main">

        {ShowSuccess && (
          <Alert variant="filled" severity="success" style={{ marginTop: 20 }}>
            {MsgSuccess}
          </Alert>
        )}
        {showError && (
          <Alert variant="filled" severity="error" style={{ marginTop: 20 }}>
            {msgError}
          </Alert>
        )}

        <Grid container marginTop={5} spacing={1}>
          <Grid item md={3} sm={4} xs={6}>
            <Button style={selectedType === "" ? btnPrimaryStyle : btnPrimaryOutlineStyle}
              onClick={() => setSelectedType("")}>All</Button>
          </Grid>
          {
            itemTypeList.map(itemType =>
              <Grid item md={3} sm={4} xs={6}>
                <Button style={selectedType === itemType.id ? btnPrimaryStyle : btnPrimaryOutlineStyle}
                  onClick={() => setSelectedType(itemType.id)}>{itemType.name}-{itemType.variant}</Button>
              </Grid>
            )
          }
        </Grid>

        <Grid container marginTop={5} marginBottom={5}>
          <Grid item xs={12}>
            <Typography variant="h4" fontWeight="bold" color={"#FE8A01"}>{title}</Typography>
          </Grid>
          {
            shownItem.map((item) => (
              <ItemCard
                key={item.id}
                item={item} />
            ))
          }

          {
            shownItem.length <= 0 && (
              <Grid item xs={12} textAlign="center" marginTop={3}>
                <Typography variant="h6" fontWeight="bold" color={"#FE8A01"}>No item found</Typography>
              </Grid>
            )
          }
        </Grid>
      </Container>
    </>
  )
}

export default MoreItemsPage
import axios from "axios"

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const cleanedBackendUrl = backendUrl.replace("/backend", "");

const CustomerService = {
  getItemTypeList() {
    return new Promise((resolve, reject) => {
      axios.get(`${cleanedBackendUrl}/items/types`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getListItem(tag) {
    return new Promise((resolve, reject) => {
      axios.get(`${cleanedBackendUrl}/items?tag=${tag}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getItemDetail(id) {
    return new Promise((resolve, reject) => {
      axios.get(`${cleanedBackendUrl}/items/${id}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}

export default CustomerService
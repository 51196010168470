import React, { useEffect, useState } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import './carousel.css'; // For styling
import noImage from "../../../assets/no-image.png";

const ImageCarousel = ({images}) => {

  const [currentIndex, setCurrentIndex] = useState(0);
  const iamgeUrl = "https://api.liquestore.com/assets/items/"

  const goToPrevious = () => {
    setCurrentIndex(currentIndex === 0 ? images.length - 1 : currentIndex - 1);
  };

  const goToNext = () => {
    if (images.length <= 0) {
      setCurrentIndex(0)
    } else {
      setCurrentIndex(currentIndex === images.length - 1 ? 0 : currentIndex + 1);
    }
  };

  useEffect(() => {
    const interval = setInterval(goToNext, 10000); // Slide every 10 seconds
    return () => clearInterval(interval); // Clear interval on component unmount
  }, [currentIndex]);

  return (
    <div className="carousel">
      <button onClick={goToPrevious} className="carousel-button">
        <KeyboardArrowLeftIcon />
      </button>
      <div className="carousel-track-wrapper">
        <div
          className="carousel-track"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
          {images.map((src, index) => {
            if (typeof src == 'string') {
              return (
                <div className='carousel-image-container' key={index}>
                  <div className='carousel-image-cover'></div>
                  <img src={iamgeUrl + src} alt={`img ${index + 1}`} className="carousel-image" />
                </div>
              )
            } else {
              return (
                <div className='carousel-image-container' key={src.id}>
                  <div className='carousel-image-cover'></div>
                  <img src={src.images == null ? noImage : iamgeUrl + src.images[0]} alt={src.name} className="carousel-image" />
                </div>
              )
            }
          })}

          {
            images.length <= 0 && (
              <div className='carousel-image-container'>
                <div className='carousel-image-cover'></div>
                <img src={noImage} alt="no_image" className="carousel-image" />
              </div>
            )
          }
        </div>
      </div>
      <button onClick={goToNext} className="carousel-button">
        <KeyboardArrowRightIcon />
      </button>
    </div>
  );
};

export default ImageCarousel;
